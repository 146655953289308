



















































import SfTabs from '/storefrontUI/components/components/organisms/SfTabs/SfTabs.vue';
import SfLoader from '/storefrontUI/components/components/atoms/SfLoader/SfLoader.vue';
import SfReview from '/storefrontUI/components/components/molecules/SfReview/SfReview.vue';
import SfRating from '/storefrontUI/components/components/atoms/SfRating/SfRating.vue';
import {
  ref, computed, defineComponent, onMounted,
} from '@nuxtjs/composition-api';
import reviewGetters from '~/modules/review/getters/reviewGetters';
import { Customer } from '~/modules/GraphQL/types';
import { useReview } from '~/modules/review/composables/useReview';

export default defineComponent({
  name: 'MyReviews',
  components: {
    SfLoader,
    SfReview,
    SfTabs,
    SfRating,
  },
  setup() {
    const { loading, loadCustomerReviews } = useReview();
    const reviews = ref<Customer['reviews'] | null>(null);

    const userReviews = computed(() => reviewGetters.getItems(reviews.value));

    onMounted(async () => {
      // @ts-expect-error loadCustomerReviews type is wrong in theme and in api-client, see M2-579
      reviews.value = await loadCustomerReviews();
    });

    return {
      reviewGetters,
      userReviews,
      loading,
      loadCustomerReviews,
    };
  },
});
